<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#313189" />
    <section v-else class="error">
      <di class="error__image"></di>
      <div class="container">
        <h1 class="error__title">{{ $prismic.richTextAsPlain(this.not_found_title) }}</h1>
        <p class="error__text">{{ $prismic.richTextAsPlain(this.not_found_text) }}</p>
        <div class="row">
          <div class="justify-content-center mt-5">
            <a :href="this.button_url.url" class="btn main-button">{{ $prismic.richTextAsPlain(this.button_label) }} <img src="@/assets/images/shared/yellow_arrow.svg" alt="arrow" /></a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'PageNotFound',
  components: {
    Spinner
  },
  data: () => {
    return {
      cmsContentPending: false,
      not_found_title: null,
      not_found_text: null,
      button_label: null,
      button_url: null
    }
  },
  methods: {
    async get404Content () {
      await this.$prismic.client.getSingle('_404page', { lang: this.$i18n.lang })
        .then((document) => {
          // _404 page
          this.not_found_title = document.data.not_found_title
          this.not_found_text = document.data.not_found_text
          this.button_label = document.data.button_label
          this.button_url = document.data.button_url
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.get404Content()
  },
  watch: {
    '$i18n.locale': function (newValue) {
      this.get404Content()
    }
  }
}
</script>
